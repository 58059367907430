<template>
  <div class="ma-2">
    <v-row align="center" justify="center" class="mt-4">
      <h1 class="text-center" style="color: #235081">รายละเอียดการสั่งซื้อ</h1>
    </v-row>

    <v-row align="center" justify="center">
      <v-card class="pa-4">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="12">
            <span style="font-size: 20px">
              <b>ชื่อผู้สั่งซื้อ: </b>
              {{ tranxDetail.user.firstName + " " + tranxDetail.user.lastName }}
            </span>
          </v-col>
          <v-col cols="12" md="12" v-if="tranxDetail.isDelivery == true">
            <b>สถานที่จัดส่ง: </b>
            <span>ที่อยู่ {{ tranxDetail.deliveryAddress.address }}</span>
            <span>
              ตำบล/เขต {{ tranxDetail.deliveryAddress.subDistrict }}
            </span>
            <span> อำเภอ/แขวง {{ tranxDetail.deliveryAddress.district }}</span>
            <span> จังหวัด {{ tranxDetail.deliveryAddress.province }} </span>
            <span>
              รหัสไปรษณีย์ {{ tranxDetail.deliveryAddress.postcode }}
            </span>
          </v-col>
          <v-col cols="12" md="12">
            <span style="font-size: 20px">
              <b>ชำระเงินเมื่อ: </b>
              {{ convertDate(tranxDetail) }}
            </span>
          </v-col>

          <v-col cols="12" md="12">
            <span style="font-size: 20px"> <b>สถานะ: </b></span>
            <span v-if="tranxDetail.status == 'รอคอนเฟิร์ม'">
              <span
                style="padding: 3px; border: 3px solid #f5222d; color: #f5222d"
                >รอการยืนยัน</span
              >
            </span>
            <span v-if="tranxDetail.status == 'รอการชำระเงิน'">
              <span
                style="padding: 3px; border: 3px solid #f5222d; color: #f5222d"
                >ไม่ได้ชำระเงิน</span
              >
            </span>
            <span v-if="tranxDetail.status == 'ชำระเงินสำเสร็จ'">
              <span
                style="padding: 3px; border: 3px solid #faad14; color: #faad14"
                >ชำระเงินสำเสร็จ</span
              >
            </span>
            <span v-if="tranxDetail.status == 'สั่งซื้อสำเร็จ'">
              <span
                style="padding: 3px; border: 3px solid #52c41a; color: #52c41a"
                >สั่งซื้อสำเร็จ</span
              >
            </span>
            <span v-if="tranxDetail.status == 'REJECT'">
              <span
                v-if="tranxDetail.slipImg == ''"
                style="padding: 3px; border: 3px solid #f5222d; color: #f5222d"
                >รออัพโหลดสลิป</span
              >
              <span v-else>มีปัญหา</span>
            </span>
            <span
              v-if="tranxDetail.status == 'ยกเลิก'"
              style="padding: 3px; border: 3px solid #f5222d; color: #f5222d"
            >
              <span>ยกเลิก</span>
            </span>
            <!-- <span v-if="tranxDetail.status == 'รอการชำระเงิน'" style="color: #235081"><b>{{ tranxDetail.status }}</b></span> -->
            <br />
          </v-col>
        </v-row>
        <!-- <v-row align="center" justify="center">
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-card outlined class="pa-4 ma-4">
              <v-row no-gutters align="center" justify="center">
                <v-col cols="12" class="pt-6">
                  <span>อัพโหลดรูปสลิปที่นี่</span>
                  <span style="color: red">*</span>
                </v-col>
                <v-col cols="11" class="mt-5 ma-10" id="step-3">
                  <v-row align="center" justify="center" @click="changePic1()">
                    <v-img
                      v-model="picture_1"
                      src="@/assets/upload.png"
                      class="mr-3"
                      max-width="30"
                    >
                    </v-img>
                    <span>เลือกรูปภาพ</span>
                  </v-row>
                  <v-row align="center" justify="center" class="mt-4">
                    <v-col
                      cols="5"
                      md="5"
                      class="
                        pl-5
                        pr-5
                        pb-5
                        row
                        fill-height
                        sortable-list
                        align-center
                      "
                    >
                      <v-card dense light class="pa-1" v-if="showImage">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-icon small light @click="RemoveImage()"
                            >mdi-close</v-icon
                          >
                        </v-card-actions>

                        <v-img
                          :src="showImage"
                          width="200px"
                          aspect-ratio="0.6"
                          contain
                          @click="newWindow(showImage)"
                        ></v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                  <input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                    hidden
                /></v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn color="#d5ab61" @click="afterTransaction()">อัพโหลด</v-btn>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="tranxDetail.powerballData"
              :search="search"
              :items-per-page="10"
              disable-sort
              hide-default-footer
              disable-pagination
              no-data-text="ไม่มีข้อมูล"
              class="elevation-1"
            >
              <template v-slot:[`item.count`]="{ index }">
                <!-- <span>{{ item.count.indexOf() + 1 }}</span> -->
                <span>{{ index + 1 }}</span>
              </template>

              <template v-slot:[`item.powerballData`]="{ item }">
                <v-chip
                  color="#005BA6"
                  style="margin-right: 1px"
                  v-for="(powerball, index) of item.set"
                  :key="`powerball_${index}`"
                >
                  <span
                    style="font-size: 1rem; font-weight: 700; color: #fff"
                    >{{ powerball }}</span
                  >
                </v-chip>
                <!-- <span v-for="(powerball, index) of item.set" :key="`powerball_${index}`">
                  {{powerball}}
                </span> -->
                <v-chip color="red" style="margin-right: 0px" v-if="trasactionType !== 'Lotteryjapan'">
                  <span
                    style="font-size: 1rem; font-weight: 700; color: #fff"
                    >{{ item.powerBall }}</span
                  >
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="6" md="6">
            <span style="font-size: 20px">
              <b v-if="trasactionType !== 'MegaMillions'">Power Play:</b>
              <b v-if="trasactionType == 'MegaMillions'">Megaplier:</b>
            </span>
            <!-- </v-col>
          <v-col cols="6" md="6"> -->
            <span style="font-size: 20px">
              <!-- <v-checkbox v-model="tranxDetail.isPowerPlay" disabled>
          </v-checkbox> -->
              <span v-if="tranxDetail.isPowerPlay">มี</span>
              <span v-if="!tranxDetail.isPowerPlay">ไม่มี</span>
            </span>
          </v-col>
          <v-col cols="12" md="12" v-if="tranxDetail.multipleAmt > 1">
            <p style="font-size: 20px">
              <b>ซื้อหลายงวด:</b> จำนวน {{ tranxDetail.multipleAmt }} งวด
            </p>
          </v-col>
          <v-col cols="12" md="12">
            <p style="font-size: 20px">
              <b>จำนวนเงินทั้งหมด:</b> {{ tranxDetail.totalPrice }} บาท
            </p>
          </v-col>

          <v-col cols="12" md="12" v-if="powerBallStatusData">
            <p style="font-size: 20px">
              <b>สถานะจาก Poweball:</b>
              {{ powerBallStatusData.result_description }}
            </p>
          </v-col>
          <v-col cols="12" md="12" v-if="powerBallStatusData.is_ticket_scanned">
            <p
              style="font-size: 20px"
              v-if="!powerBallStatusData.is_ticket_scanned"
            >
              <b>รูปสลากสแกนจากใบจริง:</b> ทางเราจะสแกนสำเนาสลากก่อนการออกรางวัล
            </p>

            <p style="color: red; font-size: 14px">
              (หากการซื้อที่ปลายทางตัวแทนในอเมริกาไม่สำเร็จ
              <br />จะมีแจ้งเตือนลูกค้าทาง SMS อีกครั้ง)
            </p>
            <p
              style="font-size: 20px"
              v-if="powerBallStatusData.is_ticket_scanned"
            >
              <b>สำเนาสลาก:</b>
            </p>
          </v-col>
          <v-col cols="12" md="12" v-if="powerBallStatusData.is_ticket_scanned">
            <img :src="powerBallStatusData.scanned_url" style="width: 80%" />
          </v-col>
          <v-col cols="12" md="12">
            <p style="font-size: 20px">
              <b>รูปสลิปโอนเงิน:</b>
            </p>
            <v-img :src="tranxDetail.slipImgUrl" max-width="320px"></v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-row class="pa-4 mb-16">
      <v-btn
        large
        @click="cancel()"
        style="
          background: linear-gradient(#3e93d7 0%, #003e81 100%);
          color: white;
        "
        >กลับ</v-btn
      >
    </v-row>
  </div>
  <!-- createdAt: (...)
id: (...)
lotteryDate: (...)
lotteryNo: (...)
owner: (...)
price: (...)
set: (...)
stock: (...)
time: (...)
updatedAt: (...)
urlImage: (...) -->

  <!-- couponCode: null
createdAt: "2021-06-28T09:32:29.000Z"
deliveryAddress: null
deliveryStatus: "WAITING"
id: 211
isDelivery: false
lotteryDate: null
lotteryTransaction: [{…}]
status: "SUCCESS"
totalPrice: 80
updatedAt: "2021-06-28T09:32:33.000Z"
userId: 4 -->
</template>
<script>
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
import moment from "moment";
export default {
  data() {
    return {
      pic1: [],
      picture_1: "",
      showImage: "",
      imageName1: "",
      trasactionId: "",
      trasactionType: "",
      tranxDetail: [],
      lotteryTransaction: [],
      search: "",
      count: 0,
      headers: [
        {
          text: "แถวที่",
          value: "count",
          align: "center",
        },
        {
          text: "เลขที่ซื้อ",
          value: "powerballData",
          align: "center",
          width: "500px",
        },
      ],
      powerBallStatusData: null,
      banners: [],
      // createdAt: (...)
      // id: (...)
      // lotteryDate: (...)
      // lotteryNo: (...)
      // owner: (...)
      // price: (...)
      // set: (...)
      // stock: (...)
      // time: (...)
      // updatedAt: (...)
      // urlImage: (...)
    };
  },
  created() {
    this.getTranxDetail();
  },
  methods: {
    async getPowerballStatus() {
      // console.log("user", user.token);
      let transactionIdCheck = this.trasactionId;
      if (this.trasactionType == "Blue") {
        transactionIdCheck = "00120" + this.trasactionId;
      } else if (this.trasactionType == "MegaMillions") {
        transactionIdCheck = "00600" + this.trasactionId;
      } else if (this.trasactionType == "Eurojackpot") {
        transactionIdCheck = "001530" + this.trasactionId;
      } else if (this.trasactionType == "Lotteryjapan") {
        transactionIdCheck = "002030" + this.trasactionId;
      }

      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/transaction${this.trasactionType}/getPowerballData/` +
          transactionIdCheck,
        {}
      );
      console.log("response powerball", response.data.data);
      this.powerBallStatusData = response.data.data
    },
    newWindow(val) {
      window.open(val);
    },
    async afterTransaction() {
      console.log("this.pi1", this.pic1);
      // console.log("length.pi1", this.pic1.length);

      this.$swal({
        text: "ยืนยันส่งหลักฐานการโอนเงิน ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยันส่งหลักฐานการโอนเงิน",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.totalPrice == 0) {
            this.$swal.fire({
              icon: "error",
              title: "ไม่มีสินค้าในตะกร้า",
              showConfirmButton: false,
              timer: 1500,
            });
            this.$router.push("/");
            console.log("เข้าไม่มีสินค้า");
          } else if (!this.pic1 || this.pic1.length == 0) {
            this.$swal.fire({
              icon: "error",
              title: "กรุณาอัพรูปหลักฐานการโอนเงิน",
              showConfirmButton: false,
              timer: 1500,
            });
            console.log("เข้าไม่มีรูป");
            // } else if (this.totalPrice !== 0 ) {
          } else if (this.totalPrice !== 0 && this.pic1.length != 0) {
            console.log("ผ่าน");
            var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
            // var paymcartsentData = JSON.parse(
            //   decode(localStorage.getItem("paymcartsentData"))
            // );
            // paymcartsentData.status = "PENDING";
            const auth = {
              headers: { Authorization: `Bearer ${user.token}` },
            };

            const data = {
              // status: paymcartsentData.status,
              // status: "PENDING",
              slipImg: this.pic1,
            };
            console.log("dataที่ส่งไปafter", data);
            console.log("this.trasactionId", this.trasactionId);
            const response = await this.axios.put(
              `${process.env.VUE_APP_API}/transaction${this.trasactionType}/update/` +
                this.trasactionId,
              data,
              auth
            );
            console.log("สำเร็จafter", response.data);
            if (response.data.response_status == "ERROR") {
              this.$swal.fire({
                icon: "warning",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.$swal.fire({
                icon: "success",
                // title: response.data.message,
                title: "อัพโหลดสลิปสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push("safe");
              localStorage.setItem("tSuccess", encode(this.trasactionId));
            }
          }
        }
      });
      //updateput trx trxid = status success
    },
    cancel() {
      this.$router.push("safe");
    },
    convertDate(value) {
      if (value.updatedAt) {
        return moment(String(value.updatedAt)).format("MM/DD/YYYY HH:mm");
      }
    },
    async getTranxDetail() {
      var transactionDetail = JSON.parse(
        decode(localStorage.getItem("transactionPowerballDetail"))
      );
      this.trasactionId = transactionDetail.id;
      this.trasactionType = transactionDetail.type;
      console.log("transactionDetail", transactionDetail);
      // transactionEurojackpot
      // transactionLotteryjapan
      // transactionMegaMillions
      // transactionBlue
      if (this.trasactionType == "powerball") {
        this.trasactionType = "";
      } else if (this.trasactionType == "megamillions") {
        this.trasactionType = "MegaMillions";
      } else if (this.trasactionType == "eurojackpot") {
        this.trasactionType = "Eurojackpot";
      } else if (this.trasactionType == "powerBallAus") {
        this.trasactionType = "Blue";
      } else if (this.trasactionType == "minilotto") {
        this.trasactionType = "Lotteryjapan";
      }

      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("user", user.token);

      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction${this.trasactionType}/getPowerballTrx/` +
          this.trasactionId,
        auth
      );
      console.log("tranxDetail", response.data.data);
      this.tranxDetail = response.data.data;
      console.log("trasactionType", this.trasactionType);
      ////
      // if (this.trasactionType !== "Lotteryjapan") {
        this.tranxDetail.powerballData = JSON.parse(
          this.tranxDetail.selectedBall
        );
      // }
      this.showImage = response.data.data.slipImg;

      this.getPowerballStatus();
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    RemoveImage(index, item) {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      // this.product_image.splice(index, 1);
      this.pic1 = [];
      this.picture_1 = "";
      this.showImage = "";
      this.imageName1 = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.table-header {
  thead {
    background-color: black;
  }
}
.theme--light.v-data-table {
  background-color: #ffffff00;
  color: rgba(0, 0, 0, 0.87);
}
.info2 {
  width: 40%;
  height: 5%;
  margin-top: -65px;
  margin-left: 120px;
  position: relative;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 5%;
  max-height: 10%;
  background: #f3e0bf73;
  backdrop-filter: blur(4px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
}
</style>
